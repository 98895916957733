import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import logo from "../../common/assets/logo-color.png";
import { Colors } from "../../product/colors/Colors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LanguageChanger } from "./LanguageChanger";
import { Hamburger } from "./Hamburger";

const HamBurgerStyle = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;

const NavbarStyled = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

const Wrapper = styled.header`
  height: 0;
  left: 0;
  width: 100%;
  height: 70px;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0px;
  font-weight: bold;
  color: #000;

  @media (max-width: 900px) {
    height: 50px;
  }
`;

const Flex = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
`;

const LogoBox = styled.div`
  height: 70px;
  overflow: hidden;
  margin-right: 30px;

  @media (max-width: 900px) {
    height: 50px;
  }
`;
const CustomLink = styled.a`
  color: inherit;
  margin: 0 10px;
  cursor: pointer;
  font-size: 1.8rem;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  padding-top: 35px;
  transition: 0.35s ease;
  text-decoration-color: var(--main-color);
  text-underline-offset: 4px;
  text-decoration: ${(props) => (props.underlined ? "underline" : "none")};

  @media (max-width: 900px) {
    display: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 9999px;
  background: var(--secondary-color);
  padding: 10px 20px;
  color: var(--main-color);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.8rem;
  position: relative;
  text-decoration: inherit;
  font-weight: 400;
  transition: 0.35s ease;
`;

const Navbar = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();
  let locationElement = location.pathname.split("/")[2];
  const [addBorder, setAddBorder] = useState(locationElement);

  useEffect(() => {
    setAddBorder(locationElement);
  }, [addBorder, setAddBorder, locationElement, location, navigate]);

  return (
    <NavbarStyled>
      <Wrapper>
        <Flex>
          <LinksWrapper>
            <Link to="/">
              <LogoBox>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "auto",
                    height: "100%",
                  }}
                />
              </LogoBox>
            </Link>
            {/* <CustomLink
                            onClick={() => navigate(`/${lang}${data[3].path}`)}
                            underlined={addBorder === 'about'}
                        >
                            {data[3].title}
                        </CustomLink>

                        <CustomLink
                            onClick={() => navigate(`/${lang}${data[2].path}`)}
                            underlined={addBorder === 'product'}
                        >
                            {data[2].title}
                        </CustomLink> */}
          </LinksWrapper>
          <LinksWrapper>
            {/* <CustomLink
                            onClick={() => navigate(`/${lang}${data[0].path}`)}
                            underlined={addBorder === 'course'}
                        >
                            {data[0].title}
                        </CustomLink>
                        <CustomLink
                            onClick={() => navigate(`/${lang}${data[1].path}`)}
                            underlined={!addBorder}
                        >
                            <Button>{data[1].title}</Button>
                        </CustomLink> */}
          </LinksWrapper>
        </Flex>
        <Flex2>
          <HamBurgerStyle>
            <Hamburger data={data} />
          </HamBurgerStyle>
        </Flex2>
      </Wrapper>
    </NavbarStyled>
  );
};

export default Navbar;

import React from "react";
import styled from "styled-components";

import england from "./images/UK.png";
import french from "./images/FR.png";
import spanish from "./images/ES.png";
import niderland from "./images/ND.png";
import germany from "./images/DE.png";
import italy from "./images/IT.png";
import russia from "./images/RU.png";
import portugal from "./images/PR.png";
import polish from "./images/PL.png";
import { Colors } from "../../../colors/Colors";

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 20px;

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 600px) {
    max-width: 350px;
  }
`;

const SectionThree = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PhotoWraper = styled.div`
  height: 110px;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 90px;
  }
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 40px;
`;

const Text = styled.div`
  text-align: center;
  color: ${Colors.mainColor};
  font-size: 16px;
  max-width: 800px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const LANGUAGES = [
  { image: england, lang: "Angličtina" },
  { image: french, lang: "Francúzština" },
  { image: spanish, lang: "Španielčina" },
  { image: niderland, lang: "Holandčina" },
  { image: germany, lang: "Nemčina" },
  { image: italy, lang: "Taliančina" },
  { image: russia, lang: "Ruština" },
  { image: portugal, lang: "Portugalčina" },
  { image: polish, lang: "Poľština" },
];

const Languages = () => {
  return (
    <>
      <SectionThree>
        <Title>Dostupné jazyky:</Title>
        <LanguageWrapper>
          {LANGUAGES.map(({ image, lang }) => {
            return (
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhotoWraper>
                  <img
                    src={image}
                    alt="logo"
                    style={{ width: "auto", height: "100%" }}
                  />
                </PhotoWraper>
              </div>
            );
          })}
        </LanguageWrapper>
      </SectionThree>
      <TextWrapper>
        <Text>
          Připravte se na příští cestu, doplňte si jazykové znalosti do
          životopisu nebo se ponořte do nové kultury: prozkoumejte rozsáhlou
          sbírku lekcí Verbalia a naučte se, co potřebujete k tomu, abyste mohli
          začít mluvit, bez ohledu na úroveň vašich zkušeností.
        </Text>
      </TextWrapper>
    </>
  );
};

export default Languages;

import england from "../images/countries/mapa_anglia.png";
import poland from "../images/countries/mapa_polska.png";
import italy from "../images/countries/mapa_wlochy.png";
import germany from "../images/countries/mapa_niemcy.png";
import niderland from "../images/countries/mapa_holandia.png";
import portugal from "../images/countries/mapa_portugalia.png";
import russia from "../images/countries/mapa_rosja.png";
import spanish from "../images/countries/mapa_hiszpania.png";
import french from "../images/countries/mapa_francja.png";

import testimonial1 from "../images/testamonial/user.png";
import testimonial2 from "../images/testamonial/user.png";
import testimonial3 from "../images/testamonial/user.png";
import testimonial4 from "../images/testamonial/user.png";
import testimonial5 from "../images/testamonial/user.png";

import brandValuesPhoto1 from "../images/brandValues/wartosci_ambicja.webp";
import brandValuesPhoto2 from "../images/brandValues/wartosci_radosc.webp";
import brandValuesPhoto3 from "../images/brandValues/wartosci_ciekawosc.webp";
import brandValuesPhoto4 from "../images/brandValues/wartosci_efektywnosc.webp";

import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import mockup from "../images/mockup_cz.png";

export const czech = {
  navbar: {
    data: [
      { title: "Objednat kurz", path: "/course" },
      { title: "Přihlásit se", path: "" },
      { title: "O platformě Verbalia", path: "/product" },
      { title: "O nás", path: "/about" },
    ],
  },
  mainPage: {
    hero: {
      title: "Kam tě zavede tvůj nový jazyk?",
      right1: "Nová přátelství?",
      right2: "Dovolená v tropech?",
      right3: "Rodina v zahraničí?",
      middle1: "Vyšší postavení?",
      middle2: "Lepší známky?",
      left1: "Práci na novém místě?",
    },
    poliglotWithNumbers: {
      title: "Verbalia v číslech",
      count: [
        {
          number: 9,
          description: " jazyků na výběr",
        },
        {
          number: 24000,
          description: "uživatelů",
        },
        {
          number: 238,
          description: "lekcí",
        },
      ],
    },
    poliglotMap: {
      title: "Co je Verbalia?",
      box1: "Slova, fráze a výrazy",
      boxDescription1:
        "Každá lekce je doprovázena pečlivě vybranými obrázky, díky nimž je proces učení intuitivní a kontextuální. Nejčastěji používaná témata pokrývají širokou škálu slovní zásoby z každodenního života a cestování, což umožňuje komunikovat v těchto oblastech bez tzv. jazykového bloku.",
      box2: "Množství lekcí",
      boxDescription2:
        "S přibývajícími zkušenostmi se zvyšuje počet lekcí, ke kterým budete mít přístup. Pokud se rozhodnete učit se od začátku, začněte s typy lekcí, které pokrývají témata od jednoduchých po složitá. Každá lekce není jen učením jednotlivých slovíček, ale obsahuje také rozmanité úkoly.",
      box3: "Pouhých 30 minut",
      boxDescription3:
        "Necítíte se pod tlakem. Lekce můžete začít a ukončit kdykoli. Nic se nestane, pokud vám každodenní povinnosti zaberou všechen čas. To je to, co náš kurz odlišuje – časová flexibilita. Doporučujeme však, abyste studiu věnovali 30 minut denně – alespoň čas od času. Tím se proces zapamatování ještě zefektivní a vy začnete mluvit cizím jazykem ještě rychleji, jako rodilí mluvčí.",
      box4: "Certifikát",
      boxDescription4:
        "Osvědčení o absolvování kurzu je velkou ctí. Každý by přece rád měl potvrzení, že získal nové dovednosti. Po absolvování všech tří úrovní kurzu a složení závěrečného testu obdržíte zdarma certifikát. Můžete si ji vytisknout a být na sebe hrdí jako na účastníka. Gratulujeme - podařilo se vám to. Ukažte ho rodině a přátelům, pochlubte se šéfovi v práci a mluvte plynně cizím jazykem!",
      box5: "3 úrovně učení",
      boxDescription5:
        "Ať už neumíte cizí jazyk vůbec, dokážete se představit, objednat si kávu nebo mluvit se zetěm ze zahraničí - Verbalia je tu pro každého. Proto je kurz rozdělen na základní, středně pokročilou a pokročilou úroveň. Na každé úrovni učební algoritmy neustále vybírají správnou slovní zásobu, aby učení nikoho nepřekvapilo příliš obtížnou úrovní. Po absolvování základní úrovně můžete přejít na střední nebo pokročilou úroveň. To jsou 3 kurzy za cenu jednoho",
    },
    avaiableLanguages: {
      title: "Dostupné jazyky",
      text: "Vždycky jste se chtěli naučit cizí jazyk? Výběr správného jazyka je důležité rozhodnutí, které může změnit váš život k lepšímu. ",
      text1:
        "Ovládání nového jazyka vám může otevřít dveře k novým příležitostem, které se vám otevřou, až se budete rozvíjet.      ",
      text2:
        "Zdokonalit se v jakémkoli jazyce vyžaduje čas, odhodlání, nadšení, motivaci a vytrvalost. Jazyková platforma Verbalia je navržena tak, aby vám pomohla naučit se jakýkoli jazyk.",
      languages: [
        { image: england, lang: "Angličtina" },
        { image: french, lang: "Francouzština" },
        { image: spanish, lang: "Španělština" },
        { image: niderland, lang: "Holandština" },
        { image: germany, lang: "Němčina" },
        { image: italy, lang: "Italština" },
        { image: russia, lang: "Ruština" },
        { image: portugal, lang: "Portugalština" },
      ],
      polish: { image: poland, lang: "Polština" },
    },
    testimonial: {
      title: "Uživatelé píší",
      elements: [
        {
          id: 1,
          photo: testimonial1,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Učí se celá rodina!",
          text: "Skvělý produkt v závislosti na jazyce, který se chcete naučit. Jejich kurz němčiny je pravděpodobně nejlepší ze všech aplikací pro výuku jazyků. Podotýkám, že nahrávky jejich kurzu polštiny jsou nekvalitní, ale na druhou stranu je těžké vůbec někde najít kurzy polštiny.          ",
          bottomInfo: "Regina Zachovalová",
        },
        {
          id: 2,
          photo: testimonial2,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Čas plyne rychleji s Verbalia!",
          text: `
           Toto je jediná aplikace pro výuku jazyků, kterou bych doporučila.
          Použila jsem mnoho jiných, Babbel, DuoLingo, Memrise, LingoDeer, ale neměla jsem pocit, že jsem se skutečně něco naučila.
          `,
          bottomInfo: "Jitka Malá",
        },
        {
          id: 3,
          photo: testimonial3,
          date: "24 stycznia 2022",
          stars: 4,
          title: "Učení pro zaneprázdněné lidi",
          text: "Na mé cestě za studiem jazyků (španělština, angličtina, francouzština, němčina a japonština) byl Verbalia jedinou aplikací, která dokázala nahradit skutečné hodiny a neměla jsem pocit, že ztrácím čas.          ",
          bottomInfo: "Andrea Šerá",
        },
        {
          id: 4,
          photo: testimonial4,
          date: "24 stycznia 2022",
          stars: 4.5,
          title: "Nyní žiji v zahraničí! ",
          text: "Používám tuto aplikaci denně a je mnohem lepší než jiné aplikace nebo webové stránky, kde mám pocit, že jsou pořád o tom samém (například Duolingo). Pomáhá vám to udržet informace v hlavě a písemné odpovědi plus možnost začít další kurzy, když platíte za premium, stojí za to... Doporučuji to každému, kdo chce zlepšit své dovednosti!!!",
          bottomInfo: "Luboš Tvrdý",
        },
        {
          id: 5,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nikdy není pozdě",
          text: "Verbalia je skvělý způsob, jak se učit jazyky a navazovat nové kontakty po celém světě.K lekcím můžete přistupovat odkudkoli ",
          bottomInfo: "Radana Hůlová",
        },
        {
          id: 6,
          photo: testimonial5,
          date: "24 stycznia 2022",
          stars: 5,
          title: "Nikdy není pozdě",
          text: "Opravdu se mi to líbí, je to velmi pohodlné a mají velmi dobrý tým podpory. Opravdu oceňuji vše, co dělají pro svět.",
          bottomInfo: "Jiří Koutný",
        },
      ],
    },
    course: {
      title: "Jaký je kurz?",
      mockupVerbalia: mockup,
      right:
        "Na platformě Verbalia nenajdete nudná, suchá gramatická pravidla, která si musíte zapamatovat za jeden den. Naučíte se věty a výrazy tak, jak se používají v každodenním životě, a poté si je upevníte v paměti pomocí jednoduchých cvičení a opakování.Kurz byl navržen tak, aby učení bylo příjemné. Během kurzu máte možnost využít:",
      right2Title: "Lekce ze skutečného života",
      right2Bottom:
        "Každá úroveň výuky se skládá z několika desítek lekcí. Pokud nevíte, na jaké jste úrovni, začněte se základy. Naučte se pozdravy, dny v týdnu, pocity. Naučte se, jak si objednat jídlo v restauraci a jak si objednat taxi. Každá lekce obsahuje příklady z každodenního života. Každá lekce je trochu jiná, skládá se z různých úkolů a cvičení, ale všechny do sebe zapadají - takže si jazyk snadno osvojíte. Lekci můžete zahájit kdykoli.",
      flexDown: [
        {
          title: "Při učení se setkáte s prvky, jako jsou:",
          text: `Nahrávky s výukou správné výslovnosti,`,
          text1: "Fráze a věty ze života,",
          text2: "Obrázky, které vám pomohou zapamatovat si slova a fráze ",
          text3: "prostřednictvím vizuálních asociací,",
          text4: "...a mnoho dalších.",
        },
        {
          title: "Cvičení",
          text: `Ať už křížovky řešíte rádi každý den, nebo se jim naopak raději vyhýbáte, nelze popřít, že tyto mentální hádanky výborně trénují paměť a umožňují opakování naučené látky.`,
        },
        {
          title: "Opakování",
          text: `Pokročilejší účastníci si mohou opakovat slovní zásobu a ověřovat své znalosti ve všech tematických oblastech v režimu průběžného učení. Obsahuje veškerou dostupnou slovní zásobu ze všech kategorií. Učící se algoritmus neustále kontroluje úroveň znalostí a přizpůsobuje další postup.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Učení s Verbalia je:",
      cards: [
        {
          icon: learning1,
          headline: `Špičková kvalita`,
          text: ` Při vývoji Verbalia se tým odborníků zaměřil na jeden nejdůležitější cíl: aby naše metoda umožnila zvládnout cizí jazyk co nejrychleji (bez ohledu na věk studenta!).
          `,
        },
        {
          icon: learning2,
          headline: `Lekce bez opuštění domova`,
          text: ` K používání aplikace Verbalia pro učení nepotřebujete nic jiného než telefon, počítač nebo tablet. S Verbalia můžete studovat kdekoli: ve frontě, v autobuse, a především můžete studovat doma a nemusíte nikam chodit. Učíte se ve vaně nebo při venčení psa? S programem Euréka je to možné!
          `,
          text2:
            " Učení s Verbalia vám tak ušetří spoustu času stráveného dojížděním a učení můžete snadno zařadit do svého rozvrhu! ",
        },
        {
          icon: learning3,
          headline: `Krátké, ale systematické lekce`,
          text: ` Při učení jazyka je nejdůležitější pravidelnost. Zároveň je pro všechny z nás obtížné najít si na to potřebný čas. S tímto vědomím byla Euréka navržena tak, aby výuka probíhala v krátkých, ale systematických lekcích.`,
        },
      ],
    },
    whyVerbalia: {
      title: "Proč  Verbalia?",
      textUp:
        "Je to platforma s mnohostranným přístupem k učení, která vám pomůže zvládnout nový jazyk tak, jak se to stalo s vaším rodným jazykem - přirozeně.",
      textBottom:
        "Učení se cizímu jazyku může změnit život. Nejenže vám otevře okno do světa plného profesních i osobních příležitostí, ale také rozvíjí mysl a posiluje sebevědomí. S tím, jak se díky technologiím, jako je internet, zmenšuje vzdálenost a my se stáváme členy globální komunity, se schopnost učit se jazyk stává nezbytnou součástí naší interakce se světem a úspěchu.",
      cards: [
        {
          headline: `Naučte se fráze a výrazy ze skutečného života pomocí naší pokročilé technologie učení.`,
          text: `Díky tisícům grafických a zvukových materiálů uvidíte a uslyšíte jako rodilí mluvčí.
          `,
        },
        {
          headline: `Získejte jistotu v mluvení. Skutečným cílem učení jazyka je také být srozumitelný.`,
          text: `S pomocí Verbalia stačí jen zopakovat slovní zásobu, kterou jste slyšeli - nebojte se mluvit! Náš systém vás opraví.
          `,
        },
        {
          headline: `Nedovolte, aby vám slova unikla z paměti. Učte se pomocí různých cvičení. `,
          text: `Prvky aplikace jsme pečlivě navrhli tak, aby se vaše mysl nikdy nenudila. Neustále připravujeme a přidáváme do Verbalia nové výzvy, aby bylo učení zábavné.
          `,
        },
        {
          headline: `Ponořte se do cizího jazyka. Snažte se plně porozumět novému jazyku na základě znalosti kontextu.`,
          text: `Díky tisícům grafických a zvukových materiálů uvidíte a uslyšíte jako rodilí mluvčí.
          `,
        },
      ],
    },
  },
  aboutUs: {
    mission: {
      title: "Poslání",
      text1:
        "Naším konečným cílem je dát každému díky technologii přístup k soukromému učiteli.",

      title2: "Univerzálně přístupné",
      textTitle2:
        "Více než 1,2 miliardy lidí se učí jazyk a většina z nich se tak snaží získat přístup k lepším příležitostem. Studium jazyka je však bohužel drahé a pro většinu lidí nedostupné.",

      title3: "Kulturní most",
      textTitle3:
        "Věříme, že jazyk je jakýmsi mostem mezi různými kulturami. Podporuje hledání porozumění a konverzaci, podporuje sledování cílů a objevování. Pro někoho je to klíč k úspěchu, pro jiného vstupenka do nového světa.",

      textBottom:
        "Proto je posláním vzdělávací platformy Verbalia zefektivnit a zpříjemnit výuku jazyků. Využitím síly technologie můžeme sjednotit svět prostřednictvím jazyka. ",
    },
    approach: {
      title: "Přístup",
      text1:
        "Z našich rozhovorů s komunitou vyplývá, že jazykovou platformu Verbalia si oblíbilo mnoho uživatelů z celého světa.",
      text2:
        "Naším posláním je překonávat komunikační bariéry a sbližovat lidi prostřednictvím jazykového vzdělávání.",
      text3:
        "Pochopení toho, za čím si stojíme a jak komunikujeme s naší komunitou, je zásadní pro to, abychom se mohli přizpůsobit potřebám naší rozmanité komunity, kterou jsme v průběhu let vybudovali.",
      paragraphData: [
        {
          id: 1,
          title: "Personalizace",
          text: "Algoritmy strojového učení neustále pracují na poskytování učebních materiálů na správné úrovni pro každého jednotlivce.          ",
        },
        {
          id: 2,
          title: "Vyvážený přístup",
          text: `Verbalia Online umožňuje studentům, aby si sami našli vzorce, aniž by se museli soustředit na pravidla jazyka – stejným způsobem, jakým jste se jako děti naučili svůj mateřský jazyk. Tento přístup, který se nazývá „implicitní učení“, je ideální na vytvoření silných základů jazyka i jeho pravidel.          `,
        },
        {
          id: 3,
          title: "Komunita",
          text: "Jazyky pomáhají lidem se zapojovat. Zkoumání jazyků podporuje porozumění. Komunita jazykové platformy Verbalia sdružuje lidi z celého světa",
        },
        {
          id: 4,
          title: "Neustálé zlepšování",
          text: "Neustále vytváříme nové a lepší kurzy, rozšiřujeme naši sbírku jazykových nástrojů a aktualizujeme naše materiály v souladu s vývojem jazyka, abychom zajistili, že i nadále budeme nabízet nejlepší jazykové vzdělávání na světě. Stejně jako jazyk, i my se stále vyvíjíme.",
        },
      ],
    },
    brandValues: {
      title: "Hodnoty značky",
      part2: {
        textInfo:
          "Hodnoty značky určují, jak komunikujeme, jak pracujeme na produktu a jak budeme jazykovou platformu Verbalia v nadcházejících letech rozvíjet.",
        brandValuesData: [
          {
            photo: brandValuesPhoto1,
            title: "Ambice",
            text: "Chceme, aby naši uživatelé uspěli bez ohledu na své cíle. Jsme ambiciózní a neustále posouváme hranice.  ",
          },
          {
            photo: brandValuesPhoto2,
            title: "Radost",
            text: "Věříme, že učení jazyků by mělo být zábavné. Pomáháme lidem získat fascinující výhody plynoucí ze znalosti cizího jazyka. ",
          },
          {
            photo: brandValuesPhoto3,
            title: "Zvědavost",
            text: "Cítíme neustálou touhu po poznání a rozvoji. S nadšením poznáváme jiné kultury. Pomáháme lidem objevovat svět prostřednictvím jazyků.",
          },
          {
            photo: brandValuesPhoto4,
            title: "Účinnost",
            text: "Chceme, aby se lidé učili jazyky chytře. Spojujeme vzdělávání s technologiemi.",
          },
        ],
      },
    },
  },
  formSender: {
    formControll: {
      titleUp: "Objednat",
      titleDown: "kurz",
      pageLink: "https://granty2050.eu/typ",
      title: "Máte zájem o kurz?",
      title1: "Chcete získat více informací?",
      subtitle: "Zanechte nám své číslo –",
      subtitle2: "zavoláme vám zpět",
      button1: "Jméno a příjmení",
      button2: "Telefonní číslo",
      button3: "E-mail",
      buttonSub: "Žádost o zavolání",
      checkOne:
        "Odesláním tohoto formuláře souhlasím s podmínkami a prohlašuji, že jsem si přečetl/a",
      checkOneTwo: "a",
      checkTwo: "Souhlasím s kontaktováním pro marketingové účely",
      textOne: "Informujeme vás, že",
      textTwo: `Správcem vašich osobních údajů je EUROPEAN EDUCATION AGENCY Spółka z Ograniczoną Odpowiedzialnością Spółka Komandytowa se sídlem ve Varšavě, Aleja "Solidarności" 117, 00-140, zapsaná v rejstříku podnikatelů vedeném Okresním soudem ve Varšavě pod číslem KRS 0000973588, NIP 5252909818, REGON: 522141653.
      Vaše osobní údaje budou zpracovávány v souvislosti s vyřízením vašeho dotazu v kontaktním formuláři nebo s plněním uzavřené smlouvy. Tím, že nás kontaktujete, dobrovolně souhlasíte se zpracováním svých osobních údajů pro výše uvedené účely. V souvislosti s naším zpracováním vašich údajů máte právo na přístup, opravu, vymazání nebo omezení zpracování, právo vznést námitku proti zpracování a právo na přenositelnost údajů. Máte také právo svůj souhlas kdykoli odvolat, ale odvolání souhlasu nemá vliv na zákonnost zpracování provedeného na základě souhlasu před jeho odvoláním. Máte také právo podat stížnost u dozorového úřadu. Poskytnutí údajů je samozřejmě dobrovolné, ale může být nezbytné pro vyřízení dotazu nebo v souvislosti s plněním smlouvy. Osobní údaje budou uchovávány po dobu nezbytnou k výše uvedeným účelům, nikoli však po dobu promlčení případných nároků podle obecných právních předpisů. Pečlivě si přečtěte zásady ochrany osobních údajů.
      `,
      buttonMore: "Přečtěte si viac",
      buttonLess: "Přečtěte si méně",
      subOne: "podmienky",
      subTwo: "a pravidlá ochrany údajov.",
      buttonTXT: "Děkujeme",
      slovakLead: "sk",
      buttonText:
        "Jeden z našich konzultantů se vám ozve během několika následujících pracovních dnů.",
      buttonText2: "Budeme v kontaktu!",
      privacyLink: "/Privacy_CZ.pdf",
      rulesLink: "/Regulations_CZ.pdf",
    },
  },
  footer: {
    privacy: "Ochrana údajů",
    privacyLink: "/ZASADY-OCHRANY-OSOBNICH-UDAJU.pdf",
    rules: "Podmínky služby",
    rulesLink: "/PODMINKY-POUZITI.pdf",
  },
};

import Store from '../../../Store';
import './HomePage.css';
import handImg from '../../../common/assets/mockup_lapka.png';
import { useState } from 'react';
import LoginModal from '../../components/Modals/LoginModal/LoginModal';
import HomepageButton from '../../components/Buttons/HomepageButton/HomepageButton';
import RegisterModal from '../../components/Modals/RegisterModal/RegisterModal';
import PasswordRecoveryModal from '../../components/Modals/PasswordRecoveryModal/PasswordRecoveryModal';
import ChangePasswordHomeModal from '../../components/Modals/ChangePasswordHomeModal/ChangePasswordHomeModal';
import usePropState from '../../common/hook/usePropState';

function HomePage({
    setError,
    isInitPasswordRecoveryModalOpen,
    isInitChangePasswordModalOpen,
}) {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        usePropState(isInitChangePasswordModalOpen);
    const [isPasswordRecoveryModalOpen, setIsPasswordRecoveryModalOpen] =
        usePropState(isInitPasswordRecoveryModalOpen);

    return (
        <div className="home-page">
            {isLoginModalOpen && (
                <LoginModal
                    isModalOpen={isLoginModalOpen}
                    setIsModalOpen={setIsLoginModalOpen}
                    setError={setError}
                />
            )}
            {isRegisterModalOpen && (
                <RegisterModal
                    isModalOpen={isRegisterModalOpen}
                    setIsModalOpen={setIsRegisterModalOpen}
                    setError={setError}
                />
            )}
            {isPasswordRecoveryModalOpen && (
                <PasswordRecoveryModal
                    isModalOpen={isPasswordRecoveryModalOpen}
                    setIsModalOpen={setIsPasswordRecoveryModalOpen}
                    setError={setError}
                />
            )}
            {isChangePasswordModalOpen && (
                <ChangePasswordHomeModal
                    isModalOpen={isChangePasswordModalOpen}
                    setIsModalOpen={setIsChangePasswordModalOpen}
                    setError={setError}
                />
            )}
            <h2 className="home-page__header">
                <b>{Store.getText('homePageSloganFirstLine', true)}</b>{' '}
                {Store.getText('homePageSloganSecondLine', true)}
            </h2>
            <div className="home-page__boxes">
                <div className="home-page__box">
                    <h3 className="home-page__box-header">
                        {Store.getText('login', true)}
                    </h3>
                    <p className="home-page__box-text">
                        {Store.getText('loginBoxDescription', true)}
                    </p>
                    <HomepageButton
                        className="home-page__box-button"
                        onClick={() => setIsLoginModalOpen(true)}
                    >
                        {Store.getText('loginButtonText', true)}
                    </HomepageButton>
                </div>

                <div className="home-page__box home-page__box--transparent">
                    <h3 className="home-page__box-header">
                        {Store.getText('register', true)}
                    </h3>
                    <p className="home-page__box-text">
                        {Store.getText('registerBoxDescription', true)}
                    </p>
                    <HomepageButton
                        className="home-page__box-button"
                        onClick={() => setIsRegisterModalOpen(true)}
                    >
                        {Store.getText('registerButtonText', true)}
                    </HomepageButton>
                </div>
            </div>
            <div className="home-page__circle">
                <img src={handImg} className="home-page__circle-image" />
            </div>
        </div>
    );
}

export default HomePage;

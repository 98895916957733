import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import hero from "../../../images/zdj_1.jpg";
import cloudRightDown from "../../../images/boxes/dymek_bialy_prawo_dol.png";
import cloudRightUp from "../../../images/boxes/dymek_bialy_prawo_gora.png";
import cloudLeftDown from "../../../images/boxes/dymek_obrys_lewy_dol.png";
import cloudLefttUp from "../../../images/boxes/dymek_obrys_lewa_gora.png";

const Photo = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
const ColorChanger = styled.div`
  background: rgba(87,29,76, 0.78);
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 60px;
  max-width: 500px;
  font-weight: lighter;
  border-bottom: none;
  

  margin-left: 50px;
  @media (max-width: 800px) {
    margin-left: 0px;
    text-align: center;
  }

`;

export const Cloud = styled.div`
  background-image: ${({ img }) => `url(${img})`};
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0px 30px 0px 10px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 200px;
  height: 100%;
  justify-content: center;
  gap: 50px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const HDescription1 = styled.h1`
  border-bottom: none;
  font-weight: lighter;
  font-size: 40px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: ${Colors.yellow};
`;
const HDescription2 = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: ${Colors.yellow};
`;

const HDescription3 = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 0 10px 30px;
  text-align: center;
  color: ${Colors.yellow};
`;

const HDescription4 = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  color: ${Colors.mainColor};
`;

const HDescription5 = styled.h1`
  font-weight: lighter;
  font-size: 40px;
  max-width: 250px;
  margin: 0px;
  padding: 10px 0 10px 0;
  text-align: center;
  color: ${Colors.mainColor};
`;

const Hero = ({ data }) => {
  return (
    <>
      <Photo img={hero}>
        <ColorChanger>
          <Title>{data.title}</Title>
          <Grid>
            <div>
              <Cloud img={cloudLefttUp} style={{ marginTop: "40vh" }}>
                <HDescription1>{data.left1}</HDescription1>
              </Cloud>
            </div>
            <div>
              <Cloud img={cloudLeftDown} style={{ marginTop: "-10vh" }}>
                <HDescription2>{data.middle1}</HDescription2>
              </Cloud>
              <Cloud img={cloudLefttUp} style={{ marginTop: "25vh" }}>
                <HDescription3>{data.middle2}</HDescription3>
              </Cloud>
            </div>
            <div>
              <Cloud img={cloudRightDown} style={{ marginTop: "-15vh" }}>
                <HDescription4>{data.right1}</HDescription4>
              </Cloud>
              <Cloud img={cloudRightUp} style={{ marginTop: "15vh" }}>
                <HDescription5>{data.right2}</HDescription5>
              </Cloud>
              <Cloud img={cloudRightDown} style={{ marginTop: "20vh" }}>
                <HDescription5>{data.right3}</HDescription5>
              </Cloud>
            </div>
          </Grid>
        </ColorChanger>
      </Photo>
    </>
  );
};

export default Hero;

export const Colors = {
  mainColor: "#571D4C",
  lightBlue: "#899ed1",
  extraLightBlue: "#D0E5FF",
  yellow: "#E7CF49",
  darkDarkBlue: "rgb(48, 90, 166)",
  newYellow: "#E7CF48",
  sea: "#025072",
  newLightBlue: "#0384bc",

  verbaliaLight: "var(--light-main-color)",
};

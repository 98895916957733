import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import logo from "./images/logo_color.png";
import { Colors } from "../../product/colors/Colors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Hamburger } from "./Hamburger";

const HamBurgerStyle = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
`;
const Wrapper = styled.header`
  margin-top: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  color: white;
  font-weight: bold;
  padding-right: 30px;

  @media (max-width: 1200px) {
    padding-right: 5%;
  }

  @media (max-width: 800px) {
    margin-top: 20px;
    padding-right: 0;
  }
  @media (max-width: 600px) {
    margin-top: 0px;
    padding-right: 0;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 0 10%;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Flex2 = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
  margin-left: -50px;
`;

const LogoBox = styled.div`
  margin-top: -25px;
  height: 80px;
  overflow: hidden;
`;

const CustomLink = styled.a`
  color: ${Colors.mainColor};
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-size: 16px;

  @media (min-width: 1390px) {
    color: ${(prev) =>
      ["product", "about"].includes(prev.type) ? "white" : Colors.mainColor};
  }

  &:hover {
    border-bottom: 2px solid ${Colors.mainColor};
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const CustomLinkButton = styled.div`
  display: flex;
  width: 170px;
  height: 43px;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--secondary-color);
  color: ${Colors.mainColor};
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  transition: 0.35s ease;
  padding: 10px;
  border-radius: 15px;
  font-size: 16px;

  &:hover {
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const NewNavbar = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();
  let locationElement = location.pathname.split("/")[2];
  const [addBorder, setAddBorder] = useState(locationElement);

  useEffect(() => {
    setAddBorder(locationElement);
  }, [addBorder, setAddBorder, locationElement, location, navigate]);

  return (
    <>
      <Wrapper>
        <Flex>
          <Link to="/">
            <LogoBox>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "100%",
                  padding: "15px 10px",
                }}
              />
            </LogoBox>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: "30px",
              marginLeft: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
              }}
            >
              {/* <CustomLink
                className={addBorder === "about" ? "border " : ""}
                onClick={() => navigate(`/${lang}/about`)}
              >
                O nás
              </CustomLink>
              <CustomLink
                className={addBorder === "product" ? "border " : ""}
                onClick={() => navigate(`/${lang}/product`)}
              >
                Verbalia
              </CustomLink> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
              }}
            >
              {/* <CustomLink
                type={addBorder}
                className={addBorder === "course" ? "border " : ""}
                onClick={() => navigate(`/${lang}/course`)}
              >
                Objednat kurz
              </CustomLink>
              <CustomLinkButton onClick={() => navigate(`/${lang}`)}>
                Začít se učit
              </CustomLinkButton> */}
            </div>
          </div>
        </Flex>
        <Flex2>
          {/* <LanguageChanger /> */}
          {/* <HamBurgerStyle>
            <Hamburger data={data} />
          </HamBurgerStyle> */}
        </Flex2>
      </Wrapper>
    </>
  );
};

export default NewNavbar;

import { useEffect, useRef, useState } from "react";
import Store from "../../../../Store";
import getLevelColor from "../../../common/functions/getLevelColor";
import ActivityFeedback from "../../ActivityFeedback/ActivityFeedback";
import ActivityButton from "../../Buttons/ActivityButton/ActivityButton";
import "./UnscrambleSentenceWordsActivity.css";

function UnscrambleSentenceWordsActivity({ activityData, makeAnswer, setIsValidAnswer }) {
  const [selectedWords, setSelectedWords] = useState([]);
  const [allWords, setAllWords] = useState([]);

  const wordsRef = useRef();
  const selectedWordsRef = useRef();

  const [correctAnswer, setCorrectAnswer] = useState("");
  const [answerResult, setAnswerResult] = useState();

  useEffect(() => {
    console.log(activityData.words.word)
    const wordArr = activityData.words.word.sentenceTo
      .split(" ")
      .map((word, i) => {
        return {
          word: word,
          id: i + 1,
        };
      }).filter(word => word.word);

    setCorrectAnswer(wordArr.map(word => word.word).join(" "));
    setAllWords(wordArr.sort((a, b) => 0.5 - Math.random()));
  }, []);
  const selectWord = (word) => {
    setSelectedWords((prevState) => {
      return [...prevState, word];
    });
  };

  const unSelectWord = (word) => {
    setSelectedWords((prevState) => {
      const copySelected = [...prevState];
      const index = copySelected.findIndex((selWord) => selWord.id === word.id);
      copySelected.splice(index, 1);
      return copySelected;
    });
  };

  const checkAnswer = () => {
    if (answerResult) return;
    const selectedAnswer = selectedWords.map((word) => word.word).join(" ");

    let isCorrect = selectedAnswer === correctAnswer;
    setIsValidAnswer(isCorrect)
    setAnswerResult({ isCorrect });

  };

  useEffect(() => {
    if (wordsRef.current && selectedWordsRef.current) {
      selectedWordsRef.current.style.minHeight = `${wordsRef.current.clientHeight}px`;
    }
  }, [wordsRef.current, selectedWordsRef.current]);
  return (
    <div className="unscramble-sentence-words-activity">
      <p className="unscramble-sentence-words-activity__expression">
        {activityData.words.word.sentenceFrom}
      </p>
      <div className="unscramble-sentence-words-activity__activity-content">
        <div className="unscramble-sentence-words-activity__answer-wrapper">
          <div
            className="unscramble-sentence-words-activity__answer-letters-wrapper"
            ref={selectedWordsRef}
          >
            {selectedWords.map((word) => (
              <p
                className="unscramble-sentence-words-activity__answer-letter"
                key={word.id}
                onClick={() => unSelectWord(word)}
                style={{
                  backgroundColor: getLevelColor(activityData.level),
                  borderColor: getLevelColor(activityData.level),
                }}
              >
                {word.word}
              </p>
            ))}
          </div>
          <div className="unscramble-sentence-words-activity__answer-underline"></div>
        </div>
        <div
          className="unscramble-sentence-words-activity__letters-wrapper"
          ref={wordsRef}
        >
          {allWords.map((word) => (
            <p
              className={`unscramble-sentence-words-activity__letter 
            ${
              selectedWords.find((selectedWord) => selectedWord.id === word.id)
                ? "unscramble-sentence-words-activity__letter--selected"
                : ""
            }
            `}
              style={{ borderColor: getLevelColor(activityData.level) }}
              key={word.id}
              onClick={() => selectWord(word)}
            >
              {word.word}
            </p>
          ))}
        </div>
      </div>
      {selectedWords.length === allWords.length && (
        <ActivityButton onClick={checkAnswer}>
          {Store.getText("continueTasks")}
        </ActivityButton>
      )}
      {answerResult && (
        <ActivityFeedback isCorrect={answerResult.isCorrect} makeAnswer={makeAnswer} properAnswer={correctAnswer}></ActivityFeedback>
      )}
    </div>
  );
}

export default UnscrambleSentenceWordsActivity;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import heroImage from "./images/most_background_round_2.webp";
import mostMobile from "./images/most_square.webp";

const WrapperMobile = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1240px) {
    display: flex;
  }
`;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin: 80px 0;
  height: 350px;

  @media (max-width: 1240px) {
    display: none;
  }

  /* @media (max-width: 1390px) {
    margin: 40px 40px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  } */

  /* @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0px;
  } */
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-weight: bold;
  text-align: left;
  font-size: 60px;
  display: flex;
  justify-content: left;
  gap: 10px;

  @media (max-width: 600px) {
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 0px;
    width: fit-content;
  }
`;

const Subtitle = styled.div`
  color: ${Colors.mainColor};
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
  max-width: 400px;

  @media (max-width: 600px) {
    display: none;
    font-size: 20px;
    margin: 0px;
  }
`;

const ImageWrapper = styled.div`
  width: 1000px;
  height: 1000px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;

  z-index: -1;
  top: -200px;
  right: -250px;
`;

const ImageWrapperMobile = styled.div`
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1390px) {
    display: none;
  }
`;
const TitleMobile = styled.div`
  width: 100%;
  margin-top: -120px;
  margin-bottom: 40px;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 48px;
  display: flex;
  justify-content: center;
`;

const DescriptionMobile = styled.div`
  color: ${Colors.mainColor};
  font-size: 16px;
  text-align: center;
  margin: 30px 0;
  max-width: 400px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 600px;
  justify-content: left;
`;

const Hero = () => {
  return (
    <div>
      <Wrapper>
        <ContentWrapper>
          <TextWrapper>
            <Title>Poslání</Title>
            <Subtitle>
              Naším nejvyšším cílem ve Verbalii je umožnit každému přístup k
              osobnímu učiteli díky technologii. Věříme, že jazyk je mostem mezi
              kulturami a naším posláním je překonávat komunikační bariéry a
              sbližovat lidi prostřednictvím jazykového vzdělávání.
            </Subtitle>
          </TextWrapper>
        </ContentWrapper>
        <div style={{ width: "500px" }}></div>
        <ImageWrapper>
          <img
            style={{ height: "100%", width: "auto" }}
            src={heroImage}
            alt="Verbalia"
          ></img>
        </ImageWrapper>
      </Wrapper>
      <WrapperMobile>
        <ImageWrapperMobile>
          <img
            style={{ height: "auto", width: "100%" }}
            src={mostMobile}
            alt="Verbalia"
          />
          <TitleMobile>Poslání</TitleMobile>
        </ImageWrapperMobile>
        <DescriptionMobile>
          Naším nejvyšším cílem ve Verbalii je umožnit každému přístup k
          osobnímu učiteli díky technologii. Věříme, že jazyk je mostem mezi
          kulturami a naším posláním je překonávat komunikační bariéry a
          sbližovat lidi prostřednictvím jazykového vzdělávání.
        </DescriptionMobile>
      </WrapperMobile>
    </div>
  );
};

export default Hero;

import React from "react";
import { useParams } from "react-router";
import About from "../../components/productPage/about/About";
import Course from "../../components/productPage/course/Course";
import Hero from "../../components/productPage/hero/Hero";
import Languages from "../../components/productPage/languages/Languages";
import Learning from "../../components/productPage/learning/Learning";
import Numbers from "../../components/productPage/numbers/Number";
import Testimonials from "../../components/productPage/testimonials/Testimonials";
import Why from "../../components/productPage/why/Why";

const OldProductPage = ({ data }) => {
  return (
    <div>
      <Hero data={data.hero} />
      <Numbers data={data.poliglotWithNumbers} />
      <About data={data.poliglotMap} />
      <Languages data={data.avaiableLanguages} />
      <Testimonials data={data.testimonial} />
      <Course data={data.course} />
      <Learning data={data.learningWithVerbalia} />
      <Why data={data.whyVerbalia} />
    </div>
  );
};

export default OldProductPage;

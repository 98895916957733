import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import screen1 from "./images/1.webp";
import screen2 from "./images/2.webp";
import screen3 from "./images/3.webp";
import screen4 from "./images/4.webp";
import screen5 from "./images/5.webp";
import screen6 from "./images/6.webp";
import top from "./images/top.svg";
import bottom from "./images/down.svg";

import phone from "../../../images/newProductPage/phone/phone_mockup.png";
import Slider from "react-slick";

const Wrapper = styled.section`
  background-color: ${Colors.verbaliaLight};
  padding: 60px 0 60px 0;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: -100px;
  }
`;

const SecondSection = styled.div`
  display: flex;
  gap: 120px;
  align-items: center;
  max-width: 1200px;

  @media (max-width: 1200px) {
    gap: 60px;
  }

  @media (max-width: 1000px) {
    gap: 0px;
    max-width: 700px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: -80px;
    gap: 0px;
  }
`;
const ViedeoWrapper = styled.div`
  overflow: hidden;
  width: 500px;
  height: 375px;

  iframe {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 800px) {
    display: none;
    visibility: hidden;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900 !important;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: center;
  }
`;

const TextWrapper = styled.h5`
  color: white;
  max-width: 420px;
  font-weight: bold;
  padding: 0px 30px;

  @media (max-width: 600px) {
    max-width: 360px;
  }
`;
const Subtitle = styled.h4`
  font-size: 22px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const PhoneImageWrapper = styled.div`
  width: 250px;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding: 0px;
  top: 23px;
  left: 12px;
  width: 220px;
  border-radius: 30px !important;
  transform: translateZ(0);
  overflow: hidden;
`;

const SecondSectionTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 30px;
  margin-top: 40px;
  max-width: 1200px;

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin-top: 120px;
    flex-direction: column;
  }
`;

const SecondTextWrapper = styled.div`
  color: white;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .element__wrapper:first-of-type {
    .description {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const PHOTO_DATA = [screen1, screen2, screen3, screen4, screen5, screen6];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

const SliderMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    height: 500px;
    overflow: hidden;
    margin-bottom: 50px;
  }
`;

const SliderDestop = styled.div`
  width: 295px;
  height: 600px;
  display: flex;
  align-items: center;
  margin-top: -50px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const ImageWrapperCase = styled.div`
  overflow: hidden;
  position: absolute;
  width: 240px;
`;

const CONFIG = [
  {
    category: "Lekce z reálného života",
    description:
      "Každá úroveň výuky se skládá z desítek lekcí. Pokud nevíte, na jaké jste úrovni - začněte se základy. Naučte se pozdravy, dny v týdnu, jak si objednat jídlo v restauraci. Každá lekce obsahuje příklady z každodenního života. ",
  },
  {
    category: "Cvičení",
    description:
      "Ať už vás luštění křížovek baví denně nebo se jim naopak raději vyhýbáte, nelze popřít, že tyto mentální hádanky výborně trénují paměť a umožňují opakování naučené látky.",
  },
  {
    category: "Slova, fráze a výrazy",
    description:
      "Každá lekce je doprovázena pečlivě vybranými obrázky, díky nimž je proces učení intuitivní a kontextuální. Nejčastěji používaná témata pokrývají širokou škálu slovní zásoby z každodenního života a cestování, což umožňuje komunikovat v těchto oblastech bez tzv. jazykového bloku.",
  },
  {
    category: "Zeptejte se odborníka",
    description:
      "Máte problém týkající se jazyka nebo potřebujete pomoc s cvičením? Jsme tady, abychom vám pomohli! Naše funkce Zeptejte se odborníka vám dává možnost obrátit se na odborníka na jazyky, kdykoli potřebujete pomoc.",
  },
];

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 800px;

  :hover {
    p {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }
`;

const Category = styled.h1`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  color: ${Colors.mainColor};
  text-align: left;
  font-size: 24px;
  font-weight: 800 !important;

  img:first-of-type {
    display: none;
  }

  img:last-of-type {
    display: flex;
  }
`;

const Description = styled.p`
  color: #fff;
  font-size: 16px !important;
  display: none;
  max-width: 500px;
  animation: 1s slide-top;

  @keyframes slide-top {
    from {
      margin-top: -100px;
    }

    to {
      margin-left: 0%;
    }
  }
`;

const Learning = () => {
  const [display, setDisplay] = useState(1);

  return (
    <Wrapper>
      {/* <ViedeoWrapper>
          <iframe
            type="text/html"
            frameborder="0"
            border="0"
            cellspacing="0"
            id="Welcome to Verbalia"
            title="Welcome to Verbalia"
            allowFullScreen
            autoPlay
            sandbox="allow-scripts allow-modal allow-popups-to-escape-sandbox allow-same-origin allow-presentation"
            src="https://www.youtube.com/embed/KkqYkR4Ik80?enablejsapi=1"
          />
        </ViedeoWrapper> */}
      {/* <TextWrapper>
          <Title>Naučte sa jazyk jednoduchým spôsobom</Title>
          <SubtitleWrapper>
            <Subtitle>
              Stovky lekcií s príkladmi jazyka používaného v reálnom živote
            </Subtitle>
            <Subtitle>
              Praktické frázy vyučované v kurzoch, ktoré pripravili rodení
              hovoriaci
            </Subtitle>
            <Subtitle>
              Cvičenia efektívne vyučujú čítanie, počúvanie a hovorenie
            </Subtitle>
          </SubtitleWrapper>
        </TextWrapper> */}
      <SecondSectionTitle>
        <Title>Jak kurz vypadá?</Title>
      </SecondSectionTitle>
      <SecondSection>
        <SecondTextWrapper>
          {CONFIG.map((c, i) => (
            <ElementWrapper className="element__wrapper">
              <Category className="category">
                {c.category}
                <img src={top} />
                <img src={bottom} />
              </Category>
              <div
                style={{
                  // maxHeight: "100px",
                  maxWidth: "500px",
                  overflow: "hidden",
                }}
              >
                <Description className="description">
                  {c.description}
                </Description>
              </div>
            </ElementWrapper>
          ))}
        </SecondTextWrapper>
        <SliderMobile>
          <PhoneImageWrapper>
            <ImageWrapperCase>
              <img
                src={phone}
                alt="phone"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageWrapperCase>
            <ImageWrapper>
              <div style={{ width: "217px", overflow: "hidden" }}>
                <Slider {...settings}>
                  {PHOTO_DATA.map((data, i) => (
                    <img
                      src={data}
                      alt="Mobile Verbalia"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ))}
                </Slider>
              </div>
            </ImageWrapper>
          </PhoneImageWrapper>
        </SliderMobile>
        <SliderDestop>
          <PhoneImageWrapper>
            <ImageWrapperCase>
              <img
                src={phone}
                alt="phone"
                style={{ width: "100%", height: "auto" }}
              />
            </ImageWrapperCase>
            <ImageWrapper>
              <div style={{ width: "217px", overflow: "hidden" }}>
                <Slider {...settings}>
                  {PHOTO_DATA.map((data, i) => (
                    <img
                      src={data}
                      alt="Mobile Verbalia"
                      style={{ width: "100%", height: "auto" }}
                    />
                  ))}
                </Slider>
              </div>
            </ImageWrapper>
          </PhoneImageWrapper>
        </SliderDestop>
      </SecondSection>
    </Wrapper>
  );
};

export default Learning;

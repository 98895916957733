import { useParams } from "react-router";
import ParamsChecker from "../../ParamsChecker";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import NewNavbar from "../newNavbar/NewNavbar";
import { ProductDiv } from "./ProductWrapperCSS";

export function ProductWrapper({ children, footerData, navbarData }) {
  const { lang } = useParams();

  return (
    <ProductDiv>
      <ParamsChecker />
      {["cz", "cs"].includes(lang) ? (
        <NewNavbar data={navbarData} />
      ) : (
        <Navbar data={navbarData}></Navbar>
      )}
      {children}
      <Footer data={footerData}></Footer>
    </ProductDiv>
  );
}

import React from "react";
import Hero from "../../components/newProdictPage/Hero/Hero";
import LabelInfo from "../../components/newProdictPage/LabelInfo/LabelInfo";
import Languages from "../../components/newProdictPage/Languages/Languages";
import Learning from "../../components/newProdictPage/Learning/Learning";
import SuccessStory from "../../components/newProdictPage/successStory/SuccessStory";

const NewProductPage = () => {
  return (
    <div>
      <Hero />
      <LabelInfo />
      <Learning />
      <Languages />
      <SuccessStory />
    </div>
  );
};

export default NewProductPage;

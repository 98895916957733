import React from "react";
import { Colors } from "../../../colors/Colors";
import styled from "styled-components";
import zdjecie1 from "./images/women_square.png";
import zdjecie2 from "./images/latop_square.png";
import zdjecie3 from "./images/people_square.png";
import desktop from "./images/3 zdjecia_round_2.png";

const ImageWrapperDesktop = styled.div`
  position: absolute;
  left: -80px;
  height: 900px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const WrapperMobile = styled.div`
  display: none;
  flex-direction: column;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -4px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const InfoWrapperDesktop = styled.div`
  height: 300px;
  padding-left: 20%;

  @media (max-width: 1400px) {
    padding-left: 30%;
  }

  @media (max-width: 1200px) {
    padding-left: 50%;
  }
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title2 = styled.h1`
  font-weight: bold;
  font-size: 40px;
  gap: 10px;
  margin: 20px 0;
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    text-align: center;
    font-size: 32px;
  }
`;

const Description = styled.div`
  text-align: center;
  font-size: 16px;
  max-width: 400px;

  @media (max-width: 1000px) {
    margin: 40px 0;
    padding: 0 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 400px;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  text-align: left;
  font-size: 40px;
  display: flex;
  justify-content: left;
  gap: 10px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 0px;
    width: fit-content;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const CONFIG = [
  {
    title: "Personalizace a přístup",
    description:
      "Náš výkonný algoritmus strojového učení neustále pracuje na poskytování učebních materiálů na správné úrovni pro každého jednotlivce. Verbalia umožňuje studentům, aby si sami našli vzorce, aniž by se museli soustředit na pravidla jazyka – stejným způsobem, jakým jste se jako děti naučili svůj mateřský jazyk.",
    image: zdjecie1,
  },
  {
    title: "Odvážná kombinace technologie a vědy",
    description:
      "Verbalia využívá odvážné spojení technologie a vědeckých poznatků, které stimulují Vaši mysl k akci. Opakování nespočetných seznamů slov a frází je nudné a Váš mozek to ví. Naše metoda, která Vás nutí využívat získané znalosti různými způsoby v konkrétních okamžicích, je vzrušující a účinná - Váš mozek to ocení!  ",
    image: zdjecie2,
  },
  {
    title: "Komunita a hodnoty",
    description:
      "Jazyky pomáhají lidem se zapojovat. Studium jazyků podporuje porozumění. Komunita platformy Verbalia sdružuje lidi z celého světa. Jsme ambiciózní a stále posouváme hranice. Věříme, že učení jazyků by mělo být zábavné a jsme zvědaví na objevování nových kultur prostřednictvím jazyků.",
    image: zdjecie3,
  },
];

const Mission = () => {
  return (
    <>
      <WrapperMobile>
        {CONFIG.map((c, i) => (
          <InfoWrapper
            style={{
              background: `${i % 2 === 0 ? Colors.verbaliaLight : "white"}`,
              display: "flex",
              alignItems: "center",
              color: `${i % 2 === 0 ? "white" : Colors.mainColor}`,
            }}
          >
            <Title2>{c.title}</Title2>

            <div>
              <ImageWrapper>
                <img src={c.image} style={{ width: "100%", height: "auto" }} />
              </ImageWrapper>
            </div>
            <Section>
              <Description>{c.description}</Description>
            </Section>
          </InfoWrapper>
        ))}
      </WrapperMobile>
      <Wrapper>
        <ImageWrapperDesktop>
          <img src={desktop} style={{ height: "100%", width: "auto" }} />
        </ImageWrapperDesktop>
        {CONFIG.map((c, i) => (
          <InfoWrapperDesktop
            style={{
              background: `${i % 2 === 0 ? Colors.verbaliaLight : "white"}`,
              display: "flex",
              alignItems: "center",
              color: `${i % 2 === 0 ? "white" : Colors.mainColor}`,
            }}
          >
            <Section>
              <Title>{c.title}</Title>
              <Description>{c.description}</Description>
            </Section>
          </InfoWrapperDesktop>
        ))}
      </Wrapper>
    </>
  );
};

export default Mission;

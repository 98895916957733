import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import start from "./images/gwiazdki.png";
import icon from "./images/icon.svg";

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  display: inline-flex;
  padding: 15px 100px;
  justify-content: space-evenly;
  align-items: center;
  gap: 100px;
  border-radius: 100px;
  background: var(--main-color);
  max-width: 1000px;

  @media (max-width: 1100px) {
    gap: 0;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    gap: 20px;
    border-radius: 60px;
    padding: 15px 40px;
    margin-top: 40px;
    margin-bottom: -40px;
  }
`;

const LabelElement = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;

  @media (max-width: 600px) {
    width: 250px;
  }
`;

const ItemUp = styled.div`
  font-weight: bold;
  font-size: 24px;
`;
const ItemDown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`;

const Bold = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin: 0px 10px;
  margin-bottom: 2px;
`;

const ImageWrapper = styled.div`
  width: 150px;
  overflow: hidden;
  display: flex;

  /* @media (max-width: 600px) {
    display: none;
  } */
`;

const LabelInfo = () => {
  return (
    <Wrapper>
      <Label>
        <LabelElement>
          <ItemUp>9</ItemUp>
          <ItemDown>jazyků</ItemDown>
        </LabelElement>
        <LabelElement>
          <ImageWrapper>
            <img
              src={start}
              alt="Stars"
              style={{ width: "100%", height: "auto" }}
            ></img>
          </ImageWrapper>
          <ItemDown>14k hodnocení</ItemDown>
          <a href="#success-stories">
            <img
              src={icon}
              alt="Chevron down"
              style={{
                margin: "10px 0px 0px 0px",
              }}
            />
          </a>
        </LabelElement>
        <LabelElement>
          <ItemUp> Více než 200k</ItemUp>
          <ItemDown>studentů</ItemDown>
        </LabelElement>
      </Label>
    </Wrapper>
  );
};

export default LabelInfo;

import Store from '../../../../../../Store'
import cs from './images/cs.jpg'

export const mockImgages = {
    'cs': cs
}

export const getMockImage = () => {
    const short = Store.getUserPlatformLangShort();
    return mockImgages[short]
}
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import {
    BackgroundProductPage,
    Cloud,
    ColorChangerProductPage,
} from '../../reusable/exportedStyles/exportedStyles';
import hero from '../../../images/zdj_1.jpg';
import styled from 'styled-components';
import photo from '../../../images/boxes/dymek_bialy_prawo_gora.png';
import { Colors } from '../../../colors/Colors';
import phone from '../../../../common/assets/icon _phone_orange.png';
import computer from '../../../../common/assets/mockup_computer.png';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    max-height: auto;
    max-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 150px);
    background: transparent;

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const BackgroundDiv = styled.div`
    position: fixed;
    overflow: hidden;
    height: 200vw;
    width: 500vw;
    left: calc(100% - 50vw);
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    background: transparent;

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--light-main-color);
        border-radius: 60%;
        z-index: 0;
    }

`;

const StyledInput = styled.input`
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    background: var(--white-color);
    &::placeholder {
        color: var(--main-color);
    }

    @media (max-width: 900px) {
        background-color: var(--lighter-main-color);
    }
`;

const HeadingWrapper = styled.div`
    margin-top: 100px;
`;

const Heading = styled.h3`
    font-weight: 400 !important;
    text-align: left;
    margin-bottom: 20px;
    color: var(--main-color);
    font-weight: 700;
    font-size: 47px !important;

    @media (max-width: 900px) {
        font-size: 40px !important;
    }
`;

const HeadingBold = styled(Heading)`
    font-weight: 700 !important;
`;

const FormHeading = styled.h3`
    font-weight: 400 !important;
    text-align: left;
    margin-bottom: 40px;
    color: #fff;
    font-weight: 700;
    font-size: 20px !important;
    text-align: center;

    @media (max-width: 900px) {
        color: var(--main-color);
    }
`;

const SubmitButton = styled.button`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    gap: 5px;
    padding: 10px 20px;
    border-radius: 9999px;
    font-size: 3rem;
    color: var(--secondary-color);
    background-color: var(--main-color);
    font-weight: 700;
    font-size: 18px;
    margin: 10px 0;
`;

const Form = styled.form`
    justify-content: center;
    align-items: center;
    max-width: 430px;
    justify-self: flex-end;
    padding: 20px;
    background: transparent;

    @media (max-width: 1100px) {
        max-width: 320px;
    }
`;

const SideWrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: transparent;
    max-height: calc(100vh - 150px);
    background: transparent;

    @media (max-width: 900px) {
        min-height: calc(40vh + 200px);

    }

`;

const ComputerImage = styled.div`
    height: calc(100vh - 230px);
    width: 120vh;
    min-width: 70vw;
    position: absolute;
    z-index: -999;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-image: url(${(props) => ((props.src))});
    background-size: cover;
    background-position: center;

    @media (max-width: 1500px) {
        min-width: 65vw;

    }

    @media (max-width: 900px) {
        width: 100vw;
        height: 40vh;
    }
`;

const FormSender = ({ data }) => {
    const [text, setText] = useState('...');
    const [showMore, setShowMore] = useState(false);
    const [showButtonThx, setShowButtonThx] = useState(true);

    const inputOne = useRef();
    const inputTwo = useRef();
    const inputThree = useRef();

    const changeText = (e) => {
        setShowMore(() => !showMore);
        e.preventDefault();
        if (text === '...') {
            setText(data.textTwo);
        } else {
            setText('...');
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();

        window.gtag('event', 'form_submit');

        const dataOne = inputOne.current.value;
        const dataTwo = inputTwo.current.value;
        const dataThree = inputThree.current.value;

        inputOne.current.value = '';
        inputTwo.current.value = '';
        inputThree.current.value = '';
        setShowButtonThx(false);

        setTimeout(() => {
            setShowButtonThx(true);
        }, 6000);

        await axios
            .post(data.pageLink, {
                productVariant: data.slovakLead ? data.slovakLead : 'cz',
                phone: dataTwo,
                name: dataOne,
                email: dataThree,
                surname: 'brak',
                utmSource: 'Platform',
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {}, [
        text,
        setText,
        showMore,
        setShowMore,
        showButtonThx,
        setShowButtonThx,
    ]);

    return (
        <>
            <BackgroundDiv />

            <Wrapper>
                <SideWrapper>
                    <HeadingWrapper>
                        <HeadingBold id="formHeading">{data.title}</HeadingBold>
                        <Heading id="formHeading">{data.title1}</Heading>
                    </HeadingWrapper>
                    <ComputerImage src={computer} />
                </SideWrapper>
                <Form id="form" style={{}} onSubmit={(e) => submitForm(e)}>
                    <FormHeading id="formHeading">
                        {data.subtitle} <strong>{data.subtitle2}</strong>
                    </FormHeading>
                    <div
                        style={{
                            display: 'flex',
                            gap: '20px',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            className="input"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <StyledInput
                                ref={inputOne}
                                name="name"
                                placeholder={data.button1}
                                type="text"
                                required
                            />
                        </div>
                        <div
                            className="input"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <StyledInput
                                ref={inputTwo}
                                name="phone"
                                placeholder={data.button2}
                                type="text"
                                required
                            />
                        </div>
                        <div
                            className="input"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <StyledInput
                                ref={inputThree}
                                name="email"
                                placeholder={data.button3}
                                type="email"
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {showButtonThx ? (
                            <SubmitButton type="submit">
                                {data.buttonSub} <img src={phone} style={{width: '20px', height: '20px'}}/>
                            </SubmitButton>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                    color: Colors.mainColor,
                                }}
                            >
                                <SubmitButton>{data.buttonTXT}</SubmitButton>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <h3 style={{ textAlign: 'center' }}>
                                        {data.buttonText}
                                    </h3>
                                    <h3 style={{ textAlign: 'center' }}>
                                        {data.buttonText2}
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'grid' }}>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked="true"
                                required
                            />
                            <span style={{ paddingLeft: '10px' }}>
                                {data.checkOne}
                                <span> </span>
                                <a href={data.rulesLink} target="_blank">
                                    {data.subOne}
                                </a>
                                <span> </span>
                                <a href={data.privacyLink} target="_blank">
                                    {data.subTwo}
                                </a>
                            </span>
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked="true"
                                required
                            />
                            <span style={{ paddingLeft: '10px' }}>
                                {data.checkTwo}
                            </span>
                        </label>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            color: 'black',
                            alignItems: 'center',
                            fontSize: '10px',
                        }}
                    >
                        <p
                            style={{
                                padding: '10px',
                                paddingBottom: '0px',
                                fontSize: '10px',
                            }}
                        >
                            {data.textOne}: {text}
                        </p>{' '}
                        <button
                            style={{
                                border: 'none',
                                color: 'black',
                                background: 'none',
                                marginTop: '-2px',
                                fontSize: '10px',
                            }}
                            onClick={(e) => changeText(e)}
                        >
                            <div style={{ marginTop: '11px' }}>
                                {showMore ? data.buttonLess : data.buttonMore}
                            </div>
                        </button>
                    </div>
                </Form>
            </Wrapper>
        </>
    );
};

export default FormSender;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";

import Lucia from "../../../images/newProductPage/lucia.png";
import Veronika from "../../../images/newProductPage/veronika.png";
import Miroslav from "../../../images/newProductPage/miroslav.png";
import Emila from "../../../images/newProductPage/emilia.png";
import Michal from "../../../images/newProductPage/michal.png";
import Lukas from "../../../images/newProductPage/lukas.png";

import Slider from "react-slick";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WhitePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 30px;

  @media (max-width: 600px) {
    margin: 40px 30px 0 30px;
  }
`;

const BluePart = styled.div`
  max-width: 1200px;
  padding: 30px 30px;
  gap: 40px;

  @media (max-width: 1200px) {
    max-width: 900px;
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    margin: auto;

    @media (min-width: 600px) {
      width: 400px;
    }
  }
`;

const Title = styled.h3`
  font-size: 40px !important;
  color: ${Colors.mainColor};
  font-weight: bold !important;
  width: 100%;
  text-align: center;

  @media (max-width: 600px) {
    margin-top: -40px;
    text-align: left;
    font-size: 40px !important;
  }
`;
const Subtitle = styled.h4`
  color: ${Colors.mainColor};
  font-size: 16px !important;
`;

const Card = styled.div`
  border-radius: 8px;
  padding: 20px;
  display: flex !important;
  flex-direction: column;
  gap: 20px;
  background: #f5f5f5;
  height: 420px;
  max-width: 360px;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 440px;
  }
`;
const CardComment = styled.h4`
  color: ${Colors.mainColor};
  height: 350px;
  font-size: 22px !important;
  font-weight: normal !important;
  font-size: 16px !important;
  max-width: 300px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px !important;
    height: 300px;
  }
`;

const CardFlexWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: -60px;
`;
const CardPhoto = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`;
const CardName = styled.h3`
  color: ${Colors.mainColor};
  font-style: italic;
  font-size: 16px !important;
  width: 100px;
  text-align: center;
`;

const TitleCard = styled.h3`
  color: ${Colors.mainColor};
  text-align: center;
  font-size: 16px !important;
  font-weight: 700 !important;
`;

const DATA = [
  {
    title: "Praktické lekce",
    name: "Kateřina",
    comment: `Ať už jste začátečník, který se teprve začíná učit základy, nebo pokročilý student, který si chce rozšířit slovní zásobu, Verbalia nabízí něco pro každého. Jednou z věcí, které se mi na této platformě líbí, je rozmanitost lekcí, od objednávání jídla v restauraci až po objednávání taxíku, díky čemuž je učení realistické a praktické. Kromě toho je velkým plusem začlenění frází z reálného života a nahrávek pro správnou výslovnost.`,
    photo: Veronika,
  },
  {
    title: "Praktické lekce",
    name: "Mirka",
    comment: `Líbí se mi, že lekce Verbalia jsou přizpůsobeny reálným situacím, takže je pro mě snadné použít to, co jsem se naučila, v reálném světě. Po třech měsících studia mi byla nabídnuta nová práce! `,
    photo: Lucia,
  },
  {
    title: "Zajímavá platforma",
    name: "Jakub",
    comment: `Verbalia je vynikající platformou pro výuku jazyků. Díky rozmanitosti lekcí a cvičení je výuka zajímavá a poutavá. Díky učení jazyka jsem se přes internet seznámil s dívkou z Německa, se kterou se brzy ožením. Doporučuji 😊
    `,
    photo: Miroslav,
  },
  {
    title: "Používám v práci!",
    name: "Eva",
    comment: `Produkt Verbalia používám již několik měsíců a mohu s jistotou říci, že mi změnil život. V práci jsem byl povýšen! Vždycky jsem měl zájem naučit se nový jazyk, ale nikdy jsem na to neměl čas ani prostředky. S Verbalia se mohu učit svým vlastním tempem a podle svého vlastního rozvrhu.`,
    photo: Emila,
  },
  {
    title: "Příjemné učení",
    name: "Jiří",
    comment: `Podle mého názoru je Verbalia úžasnou platformou pro každého, kdo si chce zlepšit své jazykové znalosti. Platforma je navržena tak, aby se učení nového jazyka stalo příjemným zážitkem.
    `,
    photo: Michal,
  },
  {
    title: "Používám několik měsíců",
    name: "Martina",
    comment: `Používám Verbalia již několik měsíců a musím říct, že pro mě změnil pravidla hry. Jako osoba, která se vždycky zajímala o učení nového jazyka, ale nikdy k tomu neměla příležitost, jsem se díky Verbalia mohla učit snadno a pohodlně. Nyní rozumím lidem z různých kultur a dokážu s nimi komunikovat, což mi rozšířilo rozhled a otevřelo nové možnosti..`,
    photo: Lukas,
  },
];

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const SuccessStory = () => {
  const slider = useRef();
  const [settings, setSettings] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const toShow =
      window.innerWidth > 900 ? (window.innerWidth > 1200 ? 3 : 2) : 1;

    setSettings({
      infinite: true,
      speed: 500,
      slidesToShow: toShow,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      className: "slider-custom",
    });
  }, [slider, window.innerWidth]);

  return (
    <Wrapper id="success-stories">
      <WhitePart>
      <Title>Úspěšné příběhy</Title>
        <Subtitle>Podívejte se, jak se ostatní zdokonalují ve Verbalii</Subtitle>
      </WhitePart>
      <BluePart>
        <Slider {...settings} ref={slider}>
          {DATA.map((element) => (
            <Card>
              <TitleCard>{element.title}</TitleCard>
              <CardComment>{element.comment}</CardComment>
              <CardFlexWrapper>
                <CardPhoto>
                  <img
                    src={element.photo}
                    style={{ width: "auto", height: "100%" }}
                  />
                </CardPhoto>
                <CardName>{element.name}</CardName>
              </CardFlexWrapper>
            </Card>
          ))}
        </Slider>
      </BluePart>
    </Wrapper>
  );
};

export default SuccessStory;
